import { PageCard } from "../components/layout/PageCard.jsx";
import { TableCRUD, TableTr } from "../components/layout/TableCRUD.jsx";
import { protectedResources } from "../LoginMS/authConfig.js";
import useFetchWithMsal from "../requests/useFetchWithMsal.jsx";
import { useEffect, useState } from "react";

const Thead = () => {
  return (
    <>
      <th>Nome</th>
      <th>Valor</th>
      <th>Descrição</th>
      <th>Ativa</th>
    </>
  );
};

const Tbody = () => {
  const data = GetPermissoes();
  console.log(data);
  if (!Array.isArray(data)) {
    return null; // or some fallback UI
  }

  return (
    <>
      {data.map((item) => (
        <TableTr key={item.id}>
          <td>{item.nome}</td>
          <td>{item.valor}</td>
          <td>{item.descricao}</td>
          <td>{item.ativada ? "Sim" : "Não"}</td>
        </TableTr>
      ))}
    </>
  );
};

const GetPermissoes = () => {
  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.GppApps_ServerAPI.scopes.read,
  });
  const [permissoes, setPermissoes] = useState(null);

  useEffect(() => {
    if (!permissoes) {
      execute("GET", protectedResources.GppApps_ServerAPI.Permissoes).then(
        (response) => {
          setPermissoes(response);
        },
      );
    }
  }, [execute, permissoes]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return permissoes;
};

export const Reencaminhamentos = () => {
  return (
    <PageCard>
      <TableCRUD Thead={Thead()} Tbody={Tbody()}></TableCRUD>
    </PageCard>
  );
};
