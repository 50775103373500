import {
  BoxWithItems,
  BoxItem,
  SearchBox,
} from "../../components/layout/BoxSelectedItems.jsx";
import useFetchWithMsal from "../../requests/useFetchWithMsal.jsx";
import { protectedResources } from "../../LoginMS/authConfig.js";
import { useContext, useEffect, useState } from "react";
import { ConfirmButton } from "../../components/layout/SideBar.jsx";
import { ErrorServer } from "../../components/Errors.jsx";
import { NotificationsContext } from "../../components/utils/NotificationsContext.jsx";
import { Context } from "../../components/utils/PagePropsContext.jsx";

const GetUsers = () => {
  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.GppApps_ServerAPI.scopes.read,
  });
  const [users, setUsers] = useState(null);

  useEffect(() => {
    if (!users) {
      execute(
        "GET",
        `${protectedResources.GppApps_ServerAPI.Atribuicoes}/Accounts`,
      ).then((response) => {
        setUsers(response);
        console.log(response);
      });
    }
  }, [execute, users]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return users;
};

export const CreateAtribuicoes = ({ SetData }) => {
  const [users, setUsers] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [, SetNotifications] = useContext(NotificationsContext);
  const [, SetPageProps] = useContext(Context);
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.GppApps_ServerAPI.scopes.write,
  });
  const info = GetUsers();
  useEffect(() => {
    setUsers(info);
  }, [info]);

  const ChangeSelect = (Atribuicao) => {
    if (selectedItems.some((i) => i.id === Atribuicao.id)) {
      setSelectedItems(selectedItems.filter((i) => i.id !== Atribuicao.id));
    } else {
      setSelectedItems([...selectedItems, Atribuicao]);
    }
  };
  const handleSearch = (term) => {
    setSearchTerm(term);
  };
  const filteredUsers = users?.filter((user) =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const AddAssignment = (selectedItems, SetData) => {
    SetNotifications((prevState) => ({
      Title: "A Adicionar",
      Content: "A adicionar os utilizadores escolhidos.",
      isLoading: true,
    }));
    // Mapear selectedItems para uma lista de IDs
    const selectedIds = selectedItems.map((item) => item.id);

    execute(
      "POST",
      `${protectedResources.GppApps_ServerAPI.Atribuicoes}/Accounts`,
      selectedIds,
    ).then((response) => {
      if (response) {
        console.log(response);
        SetData((prevData) => {
          // Cria um novo mapa para armazenar os itens atualizados
          const updatedItems = new Map(prevData.map((item) => [item.id, item]));

          // Atualiza os itens existentes ou adiciona novos itens
          response.forEach((item) => {
            updatedItems.set(item.id, item);
          });

          // Converte o mapa de volta para uma lista
          return Array.from(updatedItems.values());
        });

        SetNotifications((prevState) => ({
          Title: "Adicionados",
          Content: "Os utilizadores escolhidos foram adicionados com sucesso.",
        }));

        SetPageProps((prevState) => ({
          ...prevState,
          isSideBar: false,
        }));
      } else {
        SetPageProps((prevState) => ({
          ...prevState,
          SideBarAction: <ErrorServer />,
        }));
      }
    });
  };

  return (
    <div className="w-100 h-100">
      <h3 className="fw-bold">Adicionar utilizadores</h3>
      <SearchBox onSearch={handleSearch} />
      <BoxWithItems>
        {filteredUsers != null ? (
          filteredUsers.map((item) => (
            <BoxItem
              Item={item}
              Label={item.email}
              IsSelected={selectedItems.some((i) => i.id === item.id)}
              ChangeSelect={ChangeSelect}
            ></BoxItem>
          ))
        ) : (
          <></>
        )}
      </BoxWithItems>
      <ConfirmButton
        Text={"Guardar"}
        OnConfirm={() => AddAssignment(selectedItems, SetData)}
      />
    </div>
  );
};
