import { createContext, useState } from "react";

// Criação do Context
export const NotificationsContext = createContext();

// Componente Provider
export const NotificationsProvider = (props) => {
  const [Notifications, SetNotifications] = useState({
    Title: "",
    Content: "",
    isLoading: false,
  });
  return (
    <NotificationsContext.Provider value={[Notifications, SetNotifications]}>
      {props.children}
    </NotificationsContext.Provider>
  );
};
