import { motion } from "framer-motion";
import { useContext } from "react";
import { Context } from "../utils/PagePropsContext.jsx";

export const Option = ({ Icon, Text, Event }) => {
  const [, SetPageProps] = useContext(Context);

  return (
    <motion.div
      className="h-100 d-flex align-items-center justify-content-center p-2"
      style={{ width: "fit-content", cursor: "pointer" }}
      whileHover={{ backgroundColor: "lightgray" }}
      onClick={() =>
        SetPageProps((prevState) => ({
          ...prevState,
          isSideBar: true,
          SideBarAction: Event,
        }))
      }
    >
      <i className={`bi bi-${Icon} color-icon-option`}></i>
      <span className="ms-2">{Text}</span>
    </motion.div>
  );
};

export const OptionDisable = ({ Icon, Text }) => {

  return (
    <div
      className="h-100 d-flex align-items-center justify-content-center p-2"
      style={{ width: "fit-content" }}
    >
      <i className={`bi bi-${Icon} color-icon-option`}></i>
      <span className="ms-2 opacity-75">{Text}</span>
    </div>
  );
};
