import { useContext } from "react";
import { protectedResources } from "../../LoginMS/authConfig";
import useFetchWithMsal from "../../requests/useFetchWithMsal.jsx";
import { Context } from "../../components/utils/PagePropsContext.jsx";
import { ErrorServer } from "../../components/Errors.jsx";
import { NotificationsContext } from "../../components/utils/NotificationsContext.jsx";
import { Form, FormButton } from "../../components/layout/Form.jsx";

export const DeletePermissoes = ({ Data, SetData, selectedItems }) => {
  const [, SetNotifications] = useContext(NotificationsContext);
  const [, SetPageProps] = useContext(Context);
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.GppApps_ServerAPI.scopes.write,
  });

  const handleDelete = (event) => {
    event.preventDefault();
    SetPageProps((prevState) => ({ ...prevState, isSideBar: false }));
    SetNotifications((prevState) => ({
      Title: "A Apagar",
      Content: "Estamos apagar as permissões pedidas.",
      isLoading: true,
    }));
    execute(
      "DELETE",
      protectedResources.GppApps_ServerAPI.Permissoes,
      selectedItems,
    ).then((response) => {
      if (response) {
        // Use the filter method to remove the selectedItems from the Data array
        const newData = Data.filter((item) => !selectedItems.includes(item));
        SetData(newData);
        SetNotifications((prevState) => ({
          Title: "Apagado",
          Content: "As permissões pedidas foram apagadas.",
        }));
      } else {
        SetPageProps((prevState) => ({
          ...prevState,
          SideBarAction: <ErrorServer />,
        }));
      }
    });
  };

  return (
    <div className="w-100 h-100">
      {selectedItems.length === 1 ? (
        <>
          <h3 className="fw-bold">Apagar esta permissão?</h3>
          <p className="pt-3">
            Têm a certeza que quer apagar a permissão {selectedItems[0].nome}?
            Esta ação não pode ser revertida.
          </p>
          <Form OnSubmit={handleDelete}>
            <FormButton Text={"Apagar"} />
          </Form>
        </>
      ) : (
        <>
          <h2 className="fw-bold">Apagar as permissões?</h2>
          <p className="pt-3">
            Têm a certeza que pretende apagar as seguintes permissões?
          </p>
          {selectedItems.map((item) => (
            <li>{item.nome}</li>
          ))}
          <p />
          <p>Esta ação não pode ser revertida.</p>
          <Form OnSubmit={handleDelete}>
            <FormButton Text={"Apagar"} />
          </Form>
        </>
      )}
    </div>
  );
};
