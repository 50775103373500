import { Dropdown, Nav, NavItem } from "react-bootstrap";
import { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { Link, useMatch } from "react-router-dom";

import "../../styles/Menu.css";

export const Menu = () => {
  const [isHide, setIsHide] = useState(false);

  const DropdownItem = ({ PageName, LinkTo }) => {
    const match = useMatch(LinkTo);
    return (
      <Link
        className="dropdown-item color-bg-submenu d-flex align-items-center"
        to={LinkTo}
      >
        <div
          className={`menu-subdiv-select me-2 ms-4 ${match ? "color-menu-item-selected" : ""}`}
        ></div>
        {PageName}
      </Link>
    );
  };

  const ItemWithDropdown = (props) => {
    const [show, setShow] = useState(
      JSON.parse(localStorage.getItem("dropdown" + props.PageName)) || false,
    );

    useEffect(() => {
      localStorage.setItem("dropdown" + props.PageName, JSON.stringify(show));
    }, [show, props.id, props.PageName]);
    return (
      <Dropdown show={show} onToggle={(newShow) => setShow(newShow)}>
        <motion.div
          className="w-100 h-100"
          whileHover={{
            backgroundColor: "lightgray",
          }}
        >
          <Dropdown.Toggle
            as={"button"}
            className="custom-dropdown w-100 h-100 p-2 ps-2 bg-transparent text-start color-menu-text d-flex align-items-center"
            style={{ border: "none" }}
          >
            <div className="menu-div-select"></div>
            <div className="w-100 h-100 ps-2">
              <i className={`fs-5 bi bi-${props.Icon}`}></i>
              <span className="ms-3">{props.PageName}</span>
            </div>

            <motion.i
              className="bi bi-chevron-down"
              style={{ originY: 0.5, originX: 0.5 }}
              animate={{ rotate: show ? -180 : 0 }}
              transition={{ duration: 0.15 }}
            ></motion.i>
          </Dropdown.Toggle>
        </motion.div>
        <Dropdown.Menu
          className="position-static menu-remove-translate color-bg-menu"
          style={{ border: "none" }}
        >
          {props.children}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const Item = (props) => {
    const match = useMatch(props.Link);
    return (
      <motion.div
        className="w-100 h-100"
        whileHover={{
          backgroundColor: "lightgray",
        }}
      >
        <Link className="text-decoration-none" to={props.Link}>
          <button
            className="w-100 h-100 p-2 ps-2 bg-transparent text-start color-menu-text d-flex align-items-center"
            style={{ border: "none" }}
          >
            <div
              className={`menu-div-select ${match ? "color-menu-item-selected" : ""}`}
            ></div>
            <div className="w-100 h-100 ps-2">
              <i className={`fs-5 bi bi-${props.Icon}`}></i>
              <span className="ms-3">{props.PageName}</span>
            </div>
          </button>
        </Link>
      </motion.div>
    );
  };

  const Section = (props) => {
    const isEmpty = props.children == null;

    return isEmpty ? (
      <Item
        PageName={props.PageName}
        Icon={props.Icon}
        Link={props.Link}
      ></Item>
    ) : (
      <ItemWithDropdown PageName={props.PageName} Icon={props.Icon}>
        {props.children}
      </ItemWithDropdown>
    );
  };

  const controls = useAnimation();
  useEffect(() => {
    controls.start(isHide ? { maxWidth: "50px" } : { maxWidth: "260px" });
  }, [isHide, controls]);

  return (
    <div
      id="menu-col"
      className="col-2 p-0 overflow-y-auto color-bg-menu h-100"
    >
      <Nav className="flex-column color-text-menu">
        <NavItem>
          <motion.div
            className="w-100 h-100"
            whileHover={{ backgroundColor: "lightgray" }}
          >
            <button
              className="color-menu-text w-100 h-100 text-start ps-3 p-2"
              style={{
                background: "transparent",
                border: "none",
              }}
              onClick={() => setIsHide(!isHide)}
            >
              <i className="fs-5 bi bi-list"></i>
            </button>
          </motion.div>
        </NavItem>
        <Section PageName={"Home"} Link={"/"} Icon={"house-door"}></Section>
        <Section PageName={"Administração"} Icon={"bank2"}>
          <DropdownItem
            PageName={"Permissões"}
            LinkTo={"/Permissoes"}
          ></DropdownItem>
          <DropdownItem
            PageName={"Atribuições"}
            LinkTo={"/Atribuicoes"}
          ></DropdownItem>
        </Section>
        <Section PageName={"Informática"} Icon={"bank2"}>
          <DropdownItem
            PageName={"Reencaminhamentos"}
            LinkTo={"/Reencaminhamentos"}
          ></DropdownItem>
        </Section>
      </Nav>
    </div>
  );
};
