export const Form = (props) => {
  return <form onSubmit={props.OnSubmit}>{props.children}</form>;
};

export const FormGroup = (props) => {
  return <div className="mt-3">{props.children}</div>;
};

export const FormControl = ({ Label, InputType, InputValue, SetValue }) => {
  const OnChange = (value) => {
    SetValue(value);
  };
  return (
    <>
      <label className="form-label">{Label}</label>
      <input
        className="form-control"
        type={InputType}
        value={InputValue}
        onChange={(e) => OnChange(e.target.value, SetValue)}
      />
    </>
  );
};

export const FormButton = ({ Text }) => {
  return (
    <button
      className="btn mt-3 color-bg-form-button color-form-buttonText"
      type="submit"
    >
      {Text}
    </button>
  );
};
