import { useState } from "react";

export const BoxWithItems = (props) => {
  return (
    <div
      className="w-100 h-auto pb-2 mt-2 border border-3 overflow-auto"
      style={{ maxHeight: "75vh" }}
    >
      {props.children}
    </div>
  );
};

export const BoxItem = ({ Item, Label, IsSelected, ChangeSelect }) => {
  return (
    <div className="d-flex ms-3 mt-2 align-items-center">
      <input
        className="form-check-input mt-0 border-2"
        style={{ width: "22px", height: "22px" }}
        type="checkbox"
        checked={IsSelected}
        onChange={() => ChangeSelect(Item)}
      />
      <span className="fw-bold ms-3">{Label}</span>
    </div>
  );
};
export const SearchBox = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    event.preventDefault();
    onSearch(searchTerm);
  };

  return (
    <form onSubmit={handleSearch} className="">
      <input
        type="text"
        className="form-control"
        placeholder="Pesquisar..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </form>
  );
};
