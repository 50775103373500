import { Header } from "./Header.jsx";
import { Menu } from "./Menu.jsx";
import { Col, Container, Row } from "react-bootstrap";
import { Pages } from "../../Pages.jsx";
import { Context } from "../utils/PagePropsContext.jsx";
import { useContext } from "react";
import { SideBar } from "./SideBar.jsx";

import "../../styles/Pages.css";
import { NotificationPopUp } from "./NotificationPopUp.jsx";
import { NotificationsProvider } from "../utils/NotificationsContext.jsx";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export const PageLayout = (props) => {
  const [PageProps] = useContext(Context);
  const { instance } = useMsal();

  async function getToken() {
    try {
      const account = instance.getActiveAccount();

      if (!account) {
        throw new InteractionRequiredAuthError();
      }

      const response = await instance.acquireTokenSilent({
        scopes: ["User.Read"],
        account: account,
      });

      return response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        return instance.acquireTokenPopup({
          scopes: ["User.Read"],
        });
      }
    }
  }

  // Call getToken when you need to acquire a token
  getToken();
  return (
    <Container fluid className="">
      <Row>
        <Header />
      </Row>
      <Row id="NotFixed" className="">
        <Menu />
        <NotificationsProvider>
          <NotificationPopUp />
          <SideBar />
          <Col className="d-flex flex-column p-5 pt-4">
            <h2>{PageProps.PageName}</h2>
            <hr className="mb-0 z-1" style={{ opacity: 0.2 }} />
            <Pages />
          </Col>
        </NotificationsProvider>
      </Row>
    </Container>
  );
};
