import { useContext } from "react";
import { motion } from "framer-motion";
import { Context } from "../utils/PagePropsContext.jsx";

import "../../styles/SideBar.css";

export const SideBar = () => {
  const [PageProps, SetPageProps] = useContext(Context);

  const initial = { opacity: 0, marginRight: -100 };

  const animation = PageProps.isSideBar
    ? {
        opacity: 1,
        marginRight: 0,
      }
    : {
        opacity: 0,
        marginRight: -100,
      };

  return PageProps.isSideBar ? (
    <motion.div
      className="h-100 position-fixed end-0 side-bar color-bg-sidebar"
      initial={initial}
      animate={animation}
    >
      <motion.i
        className="fs-3 bi bi-x float-end mt-2"
        style={{ cursor: "pointer" }}
        whileHover={{ backgroundColor: "lightgray" }}
        onClick={() =>
          SetPageProps((prevState) => ({
            ...prevState,
            isSideBar: false,
          }))
        }
      ></motion.i>
      <div className="w-100 h-100 pt-4 p-3">{PageProps.SideBarAction}</div>
    </motion.div>
  ) : (
    <></>
  );
};

export const ConfirmButton = ({ Text, OnConfirm }) => {
  return (
    <button
      className="btn mt-3 color-bg-form-button color-form-buttonText"
      type="submit"
      onClick={OnConfirm}
    >
      {Text}
    </button>
  );
};
