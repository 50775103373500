import { useMsal } from "@azure/msal-react";
import { Dropdown, Image, Navbar } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";

import "../../styles/Header.css";
import { Context } from "../utils/PagePropsContext.jsx";
import { Notifications } from "./Notifications.jsx";

export const Header = () => {
  const [, SetPageProps] = useContext(Context);

  const { instance } = useMsal();

  const [photoUrl, setPhotoUrl] = useState("");

  const handleLogoutRedirect = () => {
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
    });
  };

  useEffect(() => {
    if (instance) {
      instance
        .acquireTokenSilent({
          scopes: ["https://graph.microsoft.com/User.Read"],
        })
        .then((response) => {
          fetch("https://graph.microsoft.com/v1.0/me/photo/$value", {
            headers: {
              Authorization: `Bearer ${response.accessToken}`,
            },
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Erro ao buscar a foto do usuário");
              }
              return response.blob();
            })
            .then((photoBlob) => {
              // Agora você tem o Blob da foto do usuário, que pode ser usado para exibir a foto
              const url = URL.createObjectURL(photoBlob);
              console.log(url);
              setPhotoUrl(url);
            });
        });
    }
  }, [instance]);

  return (
    <>
      <Navbar
        id="Header-Navbar"
        className="w-100 ps-3 pe-3 p-2 color-bg-header position-fixed z-1 pb-0 pt-0"
      >
        <Image
          src="/GresPanariaPortugalBranco.png"
          className="menu-logo navbar-brand"
        ></Image>

        <div className="d-flex w-100 h-100 justify-content-end me-3">
          <button
            className="bg-transparent border-0 header-button color-header-button"
            onClick={() => {
              SetPageProps((prevState) => ({
                ...prevState,
                isSideBar: true,
                SideBarAction: <Notifications />,
              }));
            }}
          >
            <i className="bi bi-bell"></i>
          </button>
        </div>
        <div className="collapse navbar-collapse justify-content-end">
          <Dropdown>
            <Dropdown.Toggle
              as={Image}
              src={photoUrl}
              roundedCircle
              style={{
                width: "40px",
                height: "40px",
              }}
            />
            <Dropdown.Menu className="dropdown-menu-end ">
              <Dropdown.Item as="button" onClick={handleLogoutRedirect}>
                Sign out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Navbar>
    </>
  );
};
