import { Options } from "../../components/layout/Options.jsx";
import { useContext, useEffect, useState } from "react";
import { NotificationsContext } from "../../components/utils/NotificationsContext.jsx";
import { Context } from "../../components/utils/PagePropsContext.jsx";
import { Option } from "../../components/layout/Option.jsx";
import { ErrorServer } from "../../components/Errors.jsx";
import { protectedResources } from "../../LoginMS/authConfig.js";
import useFetchWithMsal from "../../requests/useFetchWithMsal.jsx";
import { ConfirmButton } from "../../components/layout/SideBar.jsx";
import {
  BoxItem,
  BoxWithItems,
} from "../../components/layout/BoxSelectedItems.jsx";
import { Get } from "../../requests/Requests";

export const DetailsAtribuicoes = ({ Item, Data, SetData }) => {
  const [, SetNotifications] = useContext(NotificationsContext);
  const [, SetPageProps] = useContext(Context);
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.GppApps_ServerAPI.scopes.write,
  });

  const [selectedItems, setSelectedItems] = useState([]);
  const [Permissoes, SetPermissoes] = useState(null);

  useEffect(() => {
    setSelectedItems(Item.permissoes);
  }, [Item.permissoes]);

  const info = Get(protectedResources.GppApps_ServerAPI.Permissoes);
  useEffect(() => {
    SetPermissoes(info);
  }, [SetPermissoes, info]); // A array vazia [] faz com que este useEffect seja executado apenas na montagem do componente

  const ChangeSelect = (Permissao) => {
    if (selectedItems.some((i) => i.id === Permissao.id)) {
      setSelectedItems(selectedItems.filter((i) => i.id !== Permissao.id));
    } else {
      setSelectedItems([...selectedItems, Permissao]);
    }
  };

  const SubmitAssignments = () => {
    SetNotifications((prevState) => ({
      Title: "A Editar",
      Content: "A sincronizar alterações.",
      isLoading: true,
    }));
    console.log("TESTE");
    execute(
      "PUT",
      `${protectedResources.GppApps_ServerAPI.Atribuicoes}/${Item.userAzure.id}`,
      selectedItems,
    ).then((response) => {
      if (response) {
        SetData((prevData) =>
          prevData.map((item) => (item.id === response.id ? response : item)),
        );
        SetNotifications((prevState) => ({
          Title: "Editado",
          Content: "As alterações foram sincronizadas com sucesso.",
        }));

        SetPageProps((prevState) => ({
          isSideBar: false,
        }));
      } else {
        SetPageProps((prevState) => ({
          ...prevState,
          SideBarAction: <ErrorServer />,
        }));
      }
    });
  };

  return (
    <div className="w-100 h-100">
      <div className="d-flex">
        <div className="bg-black rounded-circle page-atribuicoes-details-img">
          <img
            src={`data:image/jpeg_base64,${Item.userAzure.Foto}`}
            alt={Item.userAzure.nome}
          />
        </div>
        <div className="ms-2 d-flex flex-column justify-content-center">
          <h3 className="ms-1"> {Item.userAzure.nome}</h3>
          <Options>
            <Option Icon="bank2" Event="null" Text="batata"></Option>
          </Options>
        </div>
      </div>
      <BoxWithItems>
        {Permissoes != null ? (
          Permissoes.map((item) => (
            <BoxItem
              Item={item}
              Label={item.nome}
              IsSelected={selectedItems.some((i) => i.id === item.id)}
              ChangeSelect={ChangeSelect}
            ></BoxItem>
          ))
        ) : (
          <></>
        )}
      </BoxWithItems>
      <ConfirmButton Text={"Guardar"} OnConfirm={SubmitAssignments} />
    </div>
  );
};
