import { useContext } from "react";
import { protectedResources } from "../../LoginMS/authConfig.js";
import useFetchWithMsal from "../../requests/useFetchWithMsal.jsx";
import { Context } from "../../components/utils/PagePropsContext.jsx";
import { ErrorServer } from "../../components/Errors.jsx";
import { NotificationsContext } from "../../components/utils/NotificationsContext.jsx";
import { Form, FormButton } from "../../components/layout/Form.jsx";
import { ConfirmButton } from "../../components/layout/SideBar.jsx";

export const DeleteAtribuicoes = ({ Data, SetData, selectedItems }) => {
  const [, SetNotifications] = useContext(NotificationsContext);
  const [, SetPageProps] = useContext(Context);

  const { execute } = useFetchWithMsal({
    scopes: protectedResources.GppApps_ServerAPI.scopes.write,
  });

  const handleDelete = () => {
    SetPageProps((prevState) => ({ ...prevState, isSideBar: false }));
    SetNotifications((prevState) => ({
      Title: "A Apagar",
      Content: "Estamos apagar as permissões pedidas.",
      isLoading: true,
    }));
    execute(
      "DELETE",
      protectedResources.GppApps_ServerAPI.Atribuicoes,
      selectedItems,
    ).then((response) => {
      if (response) {
        // Use the filter method to remove the selectedItems from the Data array
        const newData = Data.filter((item) => !selectedItems.includes(item));
        SetData(newData);
        SetNotifications((prevState) => ({
          Title: "Apagado",
          Content: "As permissões pedidas foram apagadas.",
        }));
      } else {
        SetPageProps((prevState) => ({
          ...prevState,
          SideBarAction: <ErrorServer />,
        }));
      }
    });
  };

  return (
    <div className="w-100 h-100">
      {selectedItems.length === 1 ? (
        <>
          <h3 className="fw-bold">Remover Utilizador</h3>
          <p className="pt-3">
            Têm a certeza que quer remover o utilizador{" "}
            {selectedItems[0].userAzure.nome}? Esta ação não pode ser revertida.
          </p>
          <Form OnSubmit={handleDelete}>
            <FormButton Text={"Apagar"} />
          </Form>
        </>
      ) : (
        <>
          <h2 className="fw-bold">Remover Utilizadores</h2>
          <p className="pt-3">
            Têm a certeza que pretende remover os seguintes utilizadores?
          </p>
          {selectedItems.map((item) => (
            <li>{item.userAzure.nome}</li>
          ))}
          <p />
          <p>Esta ação não pode ser revertida.</p>
          <ConfirmButton Text={"Remover"} OnConfirm={handleDelete} />
        </>
      )}
    </div>
  );
};
