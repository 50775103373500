import { Route, Routes } from "react-router-dom";
import { ToDoList } from "./pages/ToDoList.jsx";
import { Home } from "./pages/Home.jsx";
import { Empresas } from "./pages/Empresas.jsx";
import { Equips } from "./pages/Equips.jsx";
import { Permissoes } from "./pages/Permissoes/Permissoes.jsx";
import { Reencaminhamentos } from "./pages/Reencaminhamentos.jsx";
import { Atribuicoes } from "./pages/Atribuicoes/Atribuicoes.jsx";

export const Pages = () => {
  return (
    <Routes>
      <Route path="/todolist" element={<ToDoList />} />

      <Route path="/" element={<Home />} />
      <Route path="/Permissoes" element={<Permissoes />} />
      <Route path="/Atribuicoes" element={<Atribuicoes />} />

      <Route path="/Empresas" element={<Empresas />} />

      <Route path="/Equips" element={<Equips />} />

      <Route path="/Reencaminhamentos" element={<Reencaminhamentos />} />
    </Routes>
  );
};
