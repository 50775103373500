import { useEffect, useState } from "react";

import { loginRequest, protectedResources } from "../LoginMS/authConfig.js";
import useFetchWithMsal from "../requests/useFetchWithMsal";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { PageCard } from "../components/layout/PageCard.jsx";

import { TableCRUD } from "../components/layout/TableCRUD.jsx";

const EquipsList = () => {
  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.GppApps_ServerAPI.scopes.read,
  });

  const [toDoListData, setToDoListData] = useState(null);

  useEffect(() => {
    if (!toDoListData) {
      execute("GET", protectedResources.GppApps_ServerAPI.Equips).then(
        (response) => {
          setToDoListData(response);
        },
      );
    }
  }, [execute, toDoListData]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  console.log(toDoListData);
  return <>{toDoListData}</>;
};

export const Equips = () => {
  const authRequest = {
    ...loginRequest,
  };
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <PageCard>
        <TableCRUD></TableCRUD>
        <EquipsList></EquipsList>
      </PageCard>
    </MsalAuthenticationTemplate>
  );
};
