import { createContext, useState } from "react";

// Criação do Context
export const Context = createContext();

// Componente Provider
export const PagePropsProvider = (props) => {
  const [PageProps, SetPageProps] = useState({
    PageName: "Home",
    isSideBar: false,
    SideBarAction: null,
  });
  return (
    <Context.Provider value={[PageProps, SetPageProps]}>
      {props.children}
    </Context.Provider>
  );
};
