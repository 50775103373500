import useFetchWithMsal from "./useFetchWithMsal";
import React, { useEffect, useState } from "react";
import { protectedResources } from "../LoginMS/authConfig";

export const Get = (url) => {
  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.GppApps_ServerAPI.scopes.read,
  });
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!data) {
      execute("GET", url).then((response) => {
        setData(response);
      });
    }
  }, [execute, data, url]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return data;
};

export const Post = (Url, NewObject) => {
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.GppApps_ServerAPI.scopes.write,
  });
  const [result, setResult] = useState(null);
  execute("POST", Url, NewObject).then((response) => {
    if (response.status === 200) {
      setResult(response);
    } else {
      setResult(null);
    }
  });
  return result;
};
