import { Options } from "../../components/layout/Options.jsx";
import { Option } from "../../components/layout/Option.jsx";
import { DeletePermissoes } from "./DeletePermissoes";
import { useContext, useEffect, useRef, useState } from "react";
import { ErrorServer } from "../../components/Errors.jsx";
import { NotificationsContext } from "../../components/utils/NotificationsContext.jsx";
import { Context } from "../../components/utils/PagePropsContext.jsx";
import useFetchWithMsal from "../../requests/useFetchWithMsal.jsx";
import { protectedResources } from "../../LoginMS/authConfig";

export const DetailsPermissoes = ({ Item, Data, SetData }) => {
  const selectedItemList = [Item]; // Cria uma lista com o Item
  const [, SetNotifications] = useContext(NotificationsContext);
  const [, SetPageProps] = useContext(Context);
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.GppApps_ServerAPI.scopes.write,
  });

  const [updateServer, setUpdateServer] = useState("");
  const [nome, setNome] = useState(Item.nome);
  const [valor, setValor] = useState(Item.valor);
  const [descricao, setDescricao] = useState(Item.descricao);
  const [ativada] = useState(Item.ativada);

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    SetNotifications((prevState) => ({
      Title: "A Editar",
      Content: "A sincronizar alterações.",
      isLoading: true,
    }));
    const newPermission = {
      id: Item.id,
      nome: nome,
      valor: valor,
      descricao: descricao,
      ativada: true,
    };

    execute(
      "PUT",
      protectedResources.GppApps_ServerAPI.Permissoes,
      newPermission,
    ).then((response) => {
      if (response) {
        SetData((prevData) =>
          prevData.map((item) => (item.id === response.id ? response : item)),
        );
        SetNotifications((prevState) => ({
          Title: "Editado",
          Content: "As alterações foram sincronizadas com sucesso.",
        }));
      } else {
        SetPageProps((prevState) => ({
          ...prevState,
          SideBarAction: <ErrorServer />,
        }));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateServer]);
  return (
    <div className="w-100 h-100">
      <h3>Permissão {Item.nome}</h3>
      <Options>
        <Option
          Icon={"trash"}
          Text={"Apagar"}
          Event={
            <DeletePermissoes
              Data={Data}
              SetData={SetData}
              selectedItems={selectedItemList}
            />
          }
        />
      </Options>
      <div className="row mt-3">
        <div className="col">
          <Property
            Name={"Nome"}
            Value={nome}
            SetValue={setNome}
            SetUpdateServer={setUpdateServer}
          />
          <Property
            Name={"Valor"}
            Value={valor}
            SetValue={setValor}
            SetUpdateServer={setUpdateServer}
          />
        </div>

        <div className="col">
          <Property
            Name={"Descrição"}
            Value={descricao}
            SetValue={setDescricao}
            SetUpdateServer={setUpdateServer}
          />
          <Property
            Name={"Ativa"}
            Value={ativada ? "Sim" : "Não"}
            SetValue={null}
            SetUpdateServer={setUpdateServer}
          />
        </div>
      </div>
    </div>
  );
};

const Property = ({ Name, Value, SetValue, SetUpdateServer }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleChange = (event) => {
    SetValue(event.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    SetUpdateServer(Value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleBlur();
    }
  };

  return (
    <div>
      <h6 className="fw-bold pt-2">{Name}</h6>
      {isEditing && SetValue != null ? (
        <input
          type="text"
          value={Value}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <p
          onClick={handleEdit}
          className={`${SetValue != null ? "color-p-detailsEdit" : ""}`}
          style={{ cursor: "pointer" }}
        >
          {Value}
        </p>
      )}
    </div>
  );
};
