import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { loginRequest } from "../../LoginMS/authConfig.js";

export const PageCard = (props) => {
  const authRequest = {
    ...loginRequest,
  };
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <div className="page-card color-bg-card">{props.children}</div>
    </MsalAuthenticationTemplate>
  );
};
