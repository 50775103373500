import { useContext, useEffect, useRef, useState } from "react";
import { NotificationsContext } from "../utils/NotificationsContext.jsx";
import SyncLoader from "react-spinners/SyncLoader";

import "../../styles/Notifications.css";

export const NotificationPopUp = () => {
  const [Notifications, ] = useContext(NotificationsContext);
  const [isHide, SetIsHide] = useState(true);
  const [isLoading, SetIsLoading] = useState(false); // Novo estado para rastrear o carregamento

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
      SetIsHide(false);
      SetIsLoading(Notifications.isLoading);
  }, [Notifications]);

  const handleClose = () => {
    SetIsHide(true);
  };

  // Renderiza um componente de carregamento se isLoading for true
  const renderLoading = () => (
    <SyncLoader className="me-2" loading={true} size={5} color="#143455" />
  );

  return isHide ? (
    <></>
  ) : (
    <div className="position-fixed end-0 m-2 notifications-popup bg-white z-3 ">
      <i className="fs-3 bi bi-x float-end" onClick={handleClose}></i>
      <div className="d-flex align-items-center mt-2">
        {isLoading && renderLoading()}
        <h5>{Notifications.Title}</h5>
      </div>

      <p>{Notifications.Content}</p>
    </div>
  );
};
