import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { PageLayout } from "./components/layout/PageLayout.jsx";
import { Login } from "./pages/Login.jsx";
import "./styles/App.css";
import "./styles/Colors.css";
import { PagePropsProvider } from "./components/utils/PagePropsContext.jsx";

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }) => {
  return (
      <MsalProvider instance={instance}>
        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <PagePropsProvider>
            <PageLayout />
          </PagePropsProvider>
        </AuthenticatedTemplate>
      </MsalProvider>
  );
};

export default App;
