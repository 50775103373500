import { PageCard } from "../../components/layout/PageCard.jsx";
import { Options } from "../../components/layout/Options.jsx";
import { TableCRUD, TableTr } from "../../components/layout/TableCRUD.jsx";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../components/utils/PagePropsContext.jsx";
import { protectedResources } from "../../LoginMS/authConfig";
import { DetailsAtribuicoes } from "./DetailsAtribuicoes.jsx";
import { Option, OptionDisable } from "../../components/layout/Option.jsx";
import { CreateAtribuicoes } from "./CreateAtribuicoes.jsx";
import { DeleteAtribuicoes } from "./DeleteAtribuicoes.jsx";
import { Get } from "../../requests/Requests";

const Thead = ({ selectAll, handleSelectAll }) => {
  return (
    <>
      <th>
        <input
          className="form-check-input"
          type="checkbox"
          checked={selectAll}
          onChange={handleSelectAll}
        />
      </th>

      <th>Nome</th>
      <th>Permissões</th>
    </>
  );
};

const Tbody = ({
  selectedItems,
  setSelectedItems,
  selectAll,
  Data,
  SetData,
  SetPageProps,
}) => {
  useEffect(() => {
    if (selectAll) {
      setSelectedItems(Data);
    } else {
      setSelectedItems([]);
    }
  }, [Data, selectAll, setSelectedItems]);

  const handleSelect = (item, isSelected) => {
    if (isSelected) {
      setSelectedItems((prev) => [...prev, item]);
    } else {
      setSelectedItems((prev) => prev.filter((i) => i.id !== item.id));
    }
  };

  const info = Get(protectedResources.GppApps_ServerAPI.Atribuicoes);
  useEffect(() => {
    SetData(info);
  }, [SetData, info]); // A array vazia [] faz com que este useEffect seja executado apenas na montagem do componente

  if (!Array.isArray(Data)) {
    return null; // or some fallback UI
  }

  const OnClickTr = (item, data, setData) => {
    SetPageProps((prevState) => ({
      ...prevState,
      isSideBar: true,
      SideBarAction: (
        <DetailsAtribuicoes Item={item} Data={data} SetData={setData} />
      ),
    }));
  };

  return Data.map((item) => (
    <TableTr
      Key={item.id}
      isSelected={selectedItems.includes(item)}
      OnClickTr={() => {
        OnClickTr(item, Data, SetData);
      }}
    >
      <td>
        <input
          className="form-check-input"
          type="checkbox"
          checked={selectedItems.some((i) => i.id === item.id)}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => handleSelect(item, e.target.checked)}
        />
      </td>
      <td>{item.userAzure.nome}</td>
      <td>{item.permissoes.map((permissao) => permissao.nome).join(" | ")}</td>
    </TableTr>
  ));
};

export const Atribuicoes = () => {
  const [, SetPageProps] = useContext(Context);
  const [Data, SetData] = useState(null);

  useEffect(() => {
    SetPageProps((prevState) => ({ ...prevState, PageName: "Atribuições" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
  };

  return (
    <PageCard>
      <Options>
        <Option
          Icon={"plus-lg"}
          Text={"Adicionar"}
          Event={<CreateAtribuicoes SetData={SetData} />}
        />
        {selectedItems.length !== 0 ? (
          <Option
            Icon={"trash"}
            Text={"Apagar"}
            Event={
              <DeleteAtribuicoes
                Data={Data}
                SetData={SetData}
                selectedItems={selectedItems}
              />
            }
          />
        ) : (
          <OptionDisable Icon={"trash"} Text={"Apagar"} />
        )}
      </Options>
      <TableCRUD
        Thead={Thead({ selectAll, handleSelectAll })}
        Tbody={Tbody({
          selectedItems,
          setSelectedItems,
          selectAll,
          Data,
          SetData,
          SetPageProps,
        })}
      ></TableCRUD>
    </PageCard>
  );
};
