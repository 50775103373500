import React, { useContext, useState } from "react";
import { protectedResources } from "../../LoginMS/authConfig";
import { Context } from "../../components/utils/PagePropsContext.jsx";
import { ErrorServer } from "../../components/Errors.jsx";
import {
  Form,
  FormButton,
  FormControl,
  FormGroup,
} from "../../components/layout/Form.jsx";
import { NotificationsContext } from "../../components/utils/NotificationsContext.jsx";
import { Post } from "../../requests/Requests";

export const CreatePermissoes = ({ Data, SetData }) => {
  const [nome, setNome] = useState("");
  const [valor, setValor] = useState("");
  const [descricao, setDescricao] = useState("");

  const [, SetNotifications] = useContext(NotificationsContext);
  const [, SetPageProps] = useContext(Context);

  const handleSubmit = (event) => {
    event.preventDefault();
    SetPageProps((prevState) => ({ ...prevState, isSideBar: false }));
    SetNotifications(() => ({
      Title: "Adicionar",
      Content: "Estamos adicionar a permissão " + nome,
      isLoading: true,
    }));

    const newPermission = {
      nome: nome,
      valor: valor,
      descricao: descricao,
      ativada: true,
    };
    let response;
    if (
      (response = Post(
        protectedResources.GppApps_ServerAPI.Permissoes,
        newPermission,
      )) !== null
    ) {
      SetData([...Data, response]);
      SetNotifications(() => ({
        Title: "Adicionado",
        Content: "A permissão " + nome + " foi adicionada com sucesso.",
      }));
    } else {
      SetPageProps((prevState) => ({
        ...prevState,
        SideBarAction: <ErrorServer />,
      }));
    }
  };

  return (
    <div className="w-100 h-100">
      <h3 className="fw-bold">Adicionar uma Permissão?</h3>

      <Form OnSubmit={handleSubmit}>
        <FormGroup>
          <FormControl
            Label={"Nome"}
            InputType={"text"}
            InputValue={nome}
            SetValue={setNome}
          />
        </FormGroup>

        <FormGroup>
          <FormControl
            Label={"Valor"}
            InputType={"text"}
            InputValue={valor}
            SetValue={setValor}
          />
        </FormGroup>

        <FormGroup>
          <FormControl
            Label={"Descrição"}
            InputType={"text"}
            InputValue={descricao}
            SetValue={setDescricao}
          />
        </FormGroup>

        <FormButton Text={"Adicionar"} />
      </Form>
    </div>
  );
};
