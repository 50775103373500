import { loginRequest } from "../LoginMS/authConfig.js";
import { useMsal } from "@azure/msal-react";

export const Login = () => {
  const { instance } = useMsal();

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  return (
    <>
      <div
        className="w-100 d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <video
          className="position-fixed w-100 h-100 object-fit-cover"
          autoPlay
          muted
          loop
        >
          <source src="gresartVideo.mp4" type="video/mp4" />
        </video>
        <div
          className="card w-25 h-25 p-4"
          style={{ backgroundColor: "rgba(255,255,255, 0.6)" }}
        >
          <h2>Login</h2>
          <div className="card-body">
            <button
              className="btn w-100 h-100 rounded bg-black text-white"
              onClick={handleLoginRedirect}
            >
              <div className="row h-100 justify-content-center align-items-center">
                <div className="col-2">
                  <i className="bi bi-microsoft fs-1"></i>
                </div>
                <div className="col">
                  <h5 className="fw-bold">Sign in with Microsoft</h5>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
