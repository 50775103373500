import { Table } from "react-bootstrap";

export const TableCRUD = (props) => {
  return (
    <Table>
      <thead>
        <tr className="color-table-header">{props.Thead}</tr>
      </thead>
      <tbody>{props.Tbody}</tbody>
    </Table>
  );
};

export const TableTr = (props) => {
  return (
    <tr
      className={`tableCRUD-td color-bg-tableCRUD-tr ${props.isSelected ? "color-bg-tableCRUD-tr-selected" : ""}`}
      key={props.Key}
      onClick={() => props.OnClickTr()}
    >
      {props.children}
    </tr>
  );
};
